import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SyntheticEvent, useEffect, useState } from 'react';
import { TabListProps } from './TabList.props';
import { useColors } from '@hooks/useColors';
import { motion } from 'framer-motion';
import theme from '@mui/theme';
import RefContainer from '@atoms/RefContainer/RefContainer';

const TabList = (props: TabListProps) => {
  const { tabs, colorVariant, paddingTop, handleScroll = false, tabHeight, parentBoxRef } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isListeningScroll, setIsListeningScroll] = useState(true);

  const handleChangeScrollHandler = (event: SyntheticEvent, newActiveTabIndex: number) => {
    setIsListeningScroll(false);
    window.scrollTo({ top: paddingTop + parentBoxRef.current.offsetTop + newActiveTabIndex * tabHeight });
    setTimeout(() => setIsListeningScroll(true), 750);
  };

  const handleChange = (event: SyntheticEvent, newActiveTabIndex: number) => {
    setActiveTabIndex(newActiveTabIndex);
  };

  const { textColor } = useColors(colorVariant);

  // useEffect(() => {
  //   if (!handleScroll || !tabs?.length || !isListeningScroll) return;
  //   const updateActiveTabIndex = () => {
  //     if (!parentBoxRef.current) return;
  //     const { top: boxTop } = parentBoxRef.current.getBoundingClientRect();
  //     const value = Math.abs(boxTop) / tabHeight;
  //     setActiveTabIndex(Math.floor(value < tabs.length ? value : tabs.length - 1));
  //   };

  //   window.addEventListener('scroll', updateActiveTabIndex);
  //   updateActiveTabIndex();

  //   return () => window.removeEventListener('scroll', updateActiveTabIndex);
  // }, [handleScroll, parentBoxRef, tabHeight, tabs, isListeningScroll]);

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', px: 24 }}>
        <Tabs
          variant="scrollable"
          sx={{
            minHeight: 'unset',
            padding: theme.spacing(8, 0),
            '.MuiTabs-flexContainer': { gap: theme.spacing(32) },
          }}
          value={activeTabIndex}
          // onChange={handleScroll ? handleChangeScrollHandler : handleChange}
          onChange={handleChange}
        >
          {tabs?.map((tab, index) => (
            <Tab
              sx={{ color: textColor, '&.Mui-selected': { color: textColor }, maxWidth: '100%' }}
              key={`${tab.title}-${index}`}
              label={tab.title}
            />
          ))}
        </Tabs>
      </Box>

      {tabs?.map((tab, index) => (
        <RefContainer key={index}>
          {(ref) => (
            <Box display={index === activeTabIndex ? 'block' : 'none'} minHeight={ref.current?.clientHeight}>
              <motion.div
                whileInView={{ opacity: 1, y: 0 }}
                ref={ref}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.4, delay: 0.25 }}
                initial={{ opacity: 0, y: -20 }}
              >
                {tab.content}
              </motion.div>
            </Box>
          )}
        </RefContainer>
      ))}
    </>
  );
};

export default TabList;
